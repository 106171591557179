/* eslint vars-on-top: 0 no-var: 0 */
import { memoize } from 'lodash';

declare global {
  var SPREADSHEETS_HOST: string;
  var PEGASUS_HOST: string;
  var INQUISITOR_HOST: string;
  var ASSET_HOST: string;
  var DATA_API_HOST: string;
  var API_HOST: string;
  var SLATER_HOST: string;
  var STATS_HOST: string;
  var IRIS_HOST: string;
  var CHANNEL_AUTH_HOST: string;
  var BULLETIN_HOST: string;
  var AUTOGRAPH_HOST: string;
  var PUSH_HOST: string;
}

const STATIC_ASSET_URL = '//d2s28ygc2k7998.cloudfront.net';

let config: Record<string, string | Record<string, string>>;

const getConfigFromMeta = memoize((): typeof config => {
  if (!window.document || !window.document.getElementById('polecat_config')) {
    return {};
  }

  const metaNode = window.document.getElementById('polecat_config');
  if (!metaNode || !(metaNode instanceof HTMLMetaElement)) {
    return {};
  }

  return JSON.parse(metaNode.content);
});

function defaultConfig() {
  return {
    ...getConfigFromMeta(),
    SpreadsheetsBaseURL: window.SPREADSHEETS_HOST,
    PegasusBaseURL: window.PEGASUS_HOST,
    InquisitorBaseURL: window.INQUISITOR_HOST,
    AssetBaseURL: window.ASSET_HOST,
    WidgetDataBaseURL: window.DATA_API_HOST,
    ManagementBaseURL: window.API_HOST,
    SlaterBaseURL: window.SLATER_HOST,
    RootURL: `${location.protocol}//${document.domain}/`,
    StatsURL: window.STATS_HOST,
    IrisBaseURL: window.IRIS_HOST,
    CustomCSSURL: 'https://s3.amazonaws.com/gecko.css/',
    CapterraBaseURL: '//ct.capterra.com',
    StaticAssetBaseURL: STATIC_ASSET_URL,
    ChannelAuthHost: window.CHANNEL_AUTH_HOST,
    BulletinBaseURL: window.BULLETIN_HOST,
    AutographBaseURL: window.AUTOGRAPH_HOST,
    PushBaseURL: `${window.PUSH_HOST}/v1/send`,
  };
}

function get() {
  return config || defaultConfig();
}

function set(newConfig: typeof config) {
  config = newConfig;
}

export default { get, set, STATIC_ASSET_URL };
